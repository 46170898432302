import Cookies from 'universal-cookie';
import Modal from '../components/Modal/Modal';
import ReactDOM from 'react-dom';
import  { Navigate  } from 'react-router-dom'
import { lt_errors } from './errors';

export function gtag() { window.dataLayer.push(arguments); }
export function formatDollar(num){
      var p = Number(num).toFixed(2).split(".");
      return "USD " + p[0].split("").reverse().reduce(function (acc, num, i, orig) {
        return num + (num !== "-" && i && !(i % 3) ? "," : "") + acc;
      }, "") + "." + p[1];
}


export function re(url='/'){
  document.location.replace(url);
}

export function userDate(date){
        if(date ==='' || date===undefined){return '-';}
 
        let week = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
        let month = ["Jan","Feb","Mar","Apr","May","June","July","Aug","Sep","Oct","Nov","Dec"];
        let d = new Date(date);
        var offset = d.getTimezoneOffset() / 60;
        d.setHours(d.getHours() + offset);
        return `${week[d.getDay()]}, ${month[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`; 
    }


export function clearCookiesAndSession(){
        return;
        const cookies = new Cookies();
        cookies.remove('lt_be_params',{ path: '/' });
        cookies.remove('lt_token',{ path: '/' });
        cookies.remove('lt_limit',{ path: '/' });
        cookies.remove('session_ID',{ path: '/' });
        sessionStorage.removeItem("session");
    }

export function is_mobile(){
  return window.screen.width >= 720 ? false:true;
}

export function mobile_collapse(e,id){
  if(is_mobile()){
    // e.target.parentElement.getElementById(id).toggle("open");
    // console.log(e.currentTarget);
    e.currentTarget.parentElement.querySelector(`#${id}`).classList.toggle("open");
    e.currentTarget.classList.toggle("open");
    
  }
}

export function year(){
  return JSON.parse(sessionStorage.getItem('lt_year'));
}

    

// export const myTimer = function(){
//   const cookie = new Cookies();
//   let timer = setInterval(function() {
//         // CHECK FOR SESSION ID
//         if(cookie.get('lt_limit') === undefined || cookie.get('lt_limit') === "undefined" ){
//           new TheHandler.handle(lt_errors[401]);
//         }
//       }, 60 * 1000); 
//   return timer;
// }

export class TheHandler{

    constructor(event){
      this.event = event;
    }

    handle(){
      let event = this.event;
        if(event.type==='error'){
            return this.errors(event);
        }else if(event.type==='success'){
            return this.success(event);
        }else if(event.type==='clear'){
            return this.clearCookiesAndSession();
        }
    }

    success(event){
        // NEW BOOKING
        if(event.status===200 && event.success==="New Booking"){
            // let modal = <Modal title={event.error} help={event.help} kill={this.killModal}/>
            // this.clearCookiesAndSession();
            // this.redirect("/booking/success")
            // this.setState({handle:<Navigate  to='/booking/success' data={event} />});
            // this.props.history.push('/booking/success');
            
        }else if(event.status===201 && event.success==="Found Booking"){
            // let modal = <Modal title={event.error} help={event.help} kill={this.killModal}/>
            // this.clearCookiesAndSession();
            // this.redirect("/booking/success")
            return <Navigate  to='/booking/success' data={event} />;
            // this.props.history.push('/booking/success');
            
        }
    }

    errors(event){
        // NO SESSION AT CHECKOUT
        if(event.status===400 && event.error==="No Session"){
            let modal = <Modal title={event.error} help={event.help} kill={this.killModal}/>
            this.setState({handle:modal});
            this.clearCookiesAndSession();
            this.redirect("/")
            
        }else if(event.status===401){
            // SESSION EXPIRED IN BROWSER
            let modal = <Modal title={event.error} help={event.help} kill={this.killModal}/>
            this.setState({handle:modal});
            this.clearCookiesAndSession();
            this.redirect("/")
            
            
        }else if(event.status===410 && event.error==="Unable to process payment"){
            // console.log(event);
            let modal = <Modal title={event.error} help={event.help} extraHelp={event.extra_help} kill={this.killModal}/>
            this.setState({handle:modal});
        }
    }

    clearCookiesAndSession(){
        const monster = new CookieMonster();
        monster.eatAllCookies();
    }

    redirect(url){
        setTimeout(()=>{document.location.replace(`${url}`)}, this.state.redirectCountDown);
    }

    re(url){
        document.location.replace(`/`);
    }

    killModal(){
        this.setState({handle:false});
    }

  



  render() { 
  return ReactDOM.createPortal(
        <div>{this.state.handle}</div>
    ,document.getElementById('portal'));
  }
}

export class CookieMonster{

  get(cookieName){
    const cookie = new Cookies();
    return cookie.get(cookieName);
  }

  setRandomCookie(){
    const now = new Date();
    const diff = 1 * 60 * 1000
    const cookies = new Cookies();
    cookies.set('lt_r', "r", { path: '/',maxAge:31556952 });
  }

  setRoomRateSelectionCookies(response){
    const now = new Date();
    const diff = response.min * 60 * 1000
    // const diff = 1 * 60 * 1000
    const checkAt = new Date(now.getTime() + diff).getTime();
    const cookies = new Cookies();
    cookies.set('session_ID', response.ID, { path: '/',maxAge:31556952 });
    cookies.set('lt_token', response.token, { path: '/',maxAge:31556952 });
    cookies.set('lt_limit', checkAt, { path: '/',maxAge:31556952 });
  }

  eatAllCookies(){
      const cookies = new Cookies();
      cookies.remove('lt_be_params',{ path: '/' });
      cookies.remove('lt_token',{ path: '/' });
      cookies.remove('lt_limit',{ path: '/' });
      cookies.remove('session_ID',{ path: '/' });
      sessionStorage.removeItem("session");
  }

  moreTime(){
    const now = new Date();
    const diff = 5 * 60 * 1000
    const checkAt = new Date(now.getTime() + diff).getTime();
    const cookies = new Cookies();
    cookies.set('lt_limit', checkAt, { path: '/',maxAge:31556952 });
  }

  check(){
    // CHECK FOR SESSION ID
      const cookie = new Cookies();
      const now = new Date();
      if(cookie.get('lt_limit') !== undefined){
        // console.log("NOW",now.getTime());
        // console.log("YUMYUM",cookie.get('lt_limit'));
        if(parseInt(now.getTime())>=parseInt(cookie.get('lt_limit')) || isNaN(cookie.get('lt_limit'))){
          this.eatAllCookies();
          new TheHandler(lt_errors[401]).handle();
        }
      }
  }

}

export function dynamicSort(property,order) {
    var sortOrder = order;
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

function formatDate(date){
        var d = new Date(date);

        var month = '' + (d.getUTCMonth() + 1);
        var day = '' + d.getUTCDate();
        var  year = d.getUTCFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        console.log(`Format ${[year, month, day].join('-')}`);
        return [year, month, day].join('-');
    }

export function GA_Search(from, to,adult,child){
  // GA 
  try {
    
    let args = {
      event:'searchAvailability',
                    from : formatDate(from),
                    to : formatDate(to),
                    adult : adult,
                    child : child
                  }

                  var gaPush = true;
                  window.dataLayer.map(dl=>{
                  var copy = {...dl};
                  delete copy["gtm.uniqueEventId"];
                  // console.log(args);
                  // console.log(dl);
                  // console.log(JSON.stringify(args)===JSON.stringify(dl));
                  if(args == copy){
                    // console.log("A");
                    gaPush = false;
                  }else{
                    console.log("B");
                    gaPush = true;
                  }
                });
                
                
                if(gaPush){
                  window.dataLayer.push(args);
                }
              } catch (error) {
                
              }
}

export class Rating{
        constructor(container,scale=5,rate=false) {
            this.rate = !rate ? 0 : rate;
            this.container = document.querySelector(container);
            this.scale = scale;
        }

        build(){
          let output = '';
            for (let index = 1; index <= this.scale; index++) {
                let star =  document.createElement("DIV");
                star.className = 'star';
                star.setAttribute("data-score",`${index}`);
                star.style=`--star-delay:${index * 0.05}s;`;
                star.innerHTML = `
                    <svg style="pointer-events: none;" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 43 43" style="enable-background:new 0 0 43 43;" xml:space="preserve">
                    
                    <polygon class="st0" points="21.5,1.7 26.4,16.9 42.3,16.8 29.4,26.2 34.3,41.3 21.5,31.9 8.7,41.3 13.6,26.2 0.7,16.8 16.6,16.9 
                        "/>
                    </svg>`;
                star.setAttribute("onClick",(event)=>{
                    console.log(event.target);
                    console.log(this);
                    let score = event.target.getAttribute("data-score");
                    this.rate = score;
                    Array.from(this.container.children).forEach(star => {
                        if( parseInt(star.getAttribute("data-score")) <= parseInt(score) ){
                            event.target.setAttribute("data-selected",1);
                        }else{
                            event.target.setAttribute("data-selected",0);
                        }
                    });
                })
                // this.container.append(star);
                output += star.outerHTML;
            }
          return output;

        }

        updateRating(rate){
            
        }
    }
