import React, { PureComponent } from 'react'
import routes from '../../data/imageRoutes';
import { Full } from './Full';

class MiniSlideshowGeneric extends PureComponent {

    state={
        images:[],
        max:0,
        count:0,
        fullSize:'',
    }

    componentWillMount(){
        var gallery=[<img onClick={(e)=>{this.full()}} index={999} key={999} src={this.props.feat} alt="room" className={`feat`} />];
            
        this.props.gallery.forEach((element,i) => {
            var image = <img onClick={(e)=>{this.full()}} index={i} key={i} src={element} alt="room" className='feat' />;
            gallery.push(image);
        });
        

        this.setState({images:gallery,max:gallery.length-1});
    }

    slide=(dir,full=false)=>{
        this.setState(prevState=>{

            var c = prevState.count + dir;
            if(c<0){c=prevState.max}
            if(c>prevState.max){c=0}
            
            let args = {};
            args.count = c;

            if(full){
                let full = <Full key={Math.floor(Math.random() * 6) + 1} img={prevState.images[c]} kill={this.killFull} slide={this.slide} thumbnails={this.thumbnails()}/>   
                args.fullSize=full;
            }

            return(args);
        }
        );
    }

    full=()=>{
        let img = this.state.images[this.state.count];
        let full = <Full key={Math.floor(Math.random() * 6) + 1} img={img} kill={this.killFull} slide={this.slide} thumbnails={this.thumbnails()}/>   
        this.setState({fullSize:full});
    }

    thumbnails=()=>{
        return <div id="thumbnails">
                    {this.state.images.map((img,i)=>{
                            return <div onClick={(e)=>{this.thisImage(i);e.stopPropagation()}}>{img}</div>;
                    })}
                </div>
    }

    thisImage=(index)=>{
        // this.slide(index)
        // console.log(index);
        this.setState(prevState=>{
            if(prevState.count!==index){
                let full = <Full key={Math.floor(Math.random() * 6) + 1} img={prevState.images[index]} kill={this.killFull} slide={this.slide} thumbnails={this.thumbnails()}/>   
                return{count:index,fullSize:full}
            }
        })
    }


    killFull=()=>{this.setState({fullSize:''})}

  render() {
      
      
    return (
        <>
            {this.state.fullSize}
            <div id="mini-slide-show">
                {this.state.images[this.state.count]}
                <button type="button" onClick={()=>{this.slide(-1)}}>❮</button>
                <button type="button" onClick={()=>{this.slide(1)}}>❯</button>
            </div>
                
        </>
    )
  }
}

export default MiniSlideshowGeneric