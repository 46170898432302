import React, { Component } from 'react'
import countries from '../../data/countries'
import US from '../../data/US'
import CA from '../../data/CA'
import GB from '../../data/GB'
import Cookies from 'universal-cookie';
import Handler from '../Handler/Handler';
import { PackReservation } from '../SynxisReservation/PackReservation';
import parse from 'html-react-parser';
import { gtag } from '../../utilities/utilities';



class Checkout extends Component {

    state={
        countries:{US,CA,GB},
        months: ["01","02","03","04","05","06","07","08","09","10","11","12"],
        states:false,
        ccType:'',
        invalid:[],
        handle:false,
        reservation:this.props.reservation,
        modifyingCard:false,
        ccExpiry:{month:false,year:false},
        msg:""
    }
    msg = React.createRef();
    ccType = React.createRef();
    ccExpiry = React.createRef();
    expiry_date = React.createRef();
    expiry_month = React.createRef();
    expiry_year = React.createRef();
    cvv = React.createRef();
    card_name = React.createRef();

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        if(params.get("d") === "ASAMFP" || params.get("d") === "h7DQQfAKKcKp0yxVGuH7mHTUxZlzU0BjbgcAj35u"){
            const cookie = new Cookies();
            let session_ID = cookie.get('session_ID')!==undefined ?cookie.get('session_ID') : "";
            console.log(`${process.env.REACT_APP_BACK}/api/debug?session_ID=${session_ID}&d=ASAMFP`);
            fetch(`${process.env.REACT_APP_BACK}/api/debug?session_ID=${session_ID}&d=ASAMFP`)
            .then(response => response.json())
            .then(response => {
                console.log(response);
                if(response.ok===true){
                    console.log(response);
                    Object.entries(response).forEach(element => {
                        try {
                            document.querySelector(`[name="${element[0]}"]`).value = element[1];
                        } catch (error) {
                        }
                    });
                }else{
                    return;
                }
                // this.setState({booking_conditions:response});
            })
            .catch(err => console.error(err));
        }
    }

    modifyPaymentMethod=(e)=>{
        this.ccType.current.disabled=!this.ccType.current.disabled;
        this.expiry_month.current.disabled=!this.expiry_month.current.disabled;
        this.expiry_year.current.disabled=!this.expiry_year.current.disabled;
        this.ccExpiry.current.disabled=!this.ccExpiry.current.disabled;
        this.cvv.current.disabled=!this.cvv.current.disabled;
        this.card_name.current.disabled=!this.card_name.current.disabled;
        let button = e.target;
        // console.log(button);
        button.innerText=this.ccType.current.disabled === true ? "Change Payment Method": "Keep previous Payment Method";
        this.setState(prevState=>{return( {modifyingCard:!prevState.modifyingCard} )})
    }

    failedBooking=(response)=>{
        let newHelp;
        let newExtraHelp;
            try {
                newHelp = parse(String(response.help));
                newExtraHelp = parse(String(response.extra_help));
            } catch (error) {
                newHelp = response.help;
                newExtraHelp = response.extra_help;
            }
        let msg = 
        <>
            <h4>Oops! Something is wrong</h4>
            {newHelp}<br/>
            {newExtraHelp}
        </>
        this.setState({msg:msg});
        this.msg.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    checkForm=(e)=>{
        e.preventDefault();
        let button = e.nativeEvent.submitter;
        let checks = [];
        let modify = this.state.reservation !== false ? PackReservation(this.state.reservation).output : false;

        // console.log("CCTYPE",this.ccType);

        if(modify && this.state.modifyingCard){
            checks.push({field:this.ccType.current,validation:this.ccValidType});
            // checks.push({field:this.ccExpiry.current,validation:this.ccExpired});
        }

        if(!modify){
            checks.push({field:this.ccType.current,validation:this.ccValidType});
            checks.push({field:this.ccExpiry.current,validation:this.ccExpired});
        }

        let invalid = checks.map(f=>{
            // console.log(f);
            return {ref:f.field,response:f.validation(f.field.value)};
        });
        // console.log("INVALIDs",invalid);

        let invalidCount = invalid.some(v=>{
            if(!v.response.pass){
                var ref = v.ref;
                if(v.response.hasOwnProperty("focus")){ref=v.response.focus}
                ref.scrollIntoView({ behavior: 'smooth', block: 'center' });
                ref.classList.add("invalid");
                return true;
            }
        });

        if(!invalidCount){
            return true;
        }else{
            button.disabled=false;
            button.innerText="Complete Booking";
            return false;
        }

    }

    checkout=(e)=>{
        e.preventDefault();
        let params = new URLSearchParams(window.location.search);
        let d = params.get("d");
        let button = e.nativeEvent.submitter;
        button.disabled=true;
        button.innerText="Processing Reservation...";
        let type="create";
        let modify = this.state.reservation !==false?PackReservation(this.state.reservation).output : false;

        if(d!=="ASAMFP" && d !== "h7DQQfAKKcKp0yxVGuH7mHTUxZlzU0BjbgcAj35u"){
            if(!this.checkForm(e)){return;}
        }
        // console.log(modify);
        const cookie = new Cookies();
        let session_ID = cookie.get('session_ID')!==undefined ?cookie.get('session_ID') : "";
        let data = new FormData(e.target);
        let body = {        
            first_name: data.get('first_name'),
            last_name: data.get('last_name'),
            phone: data.get('phone'),
            email: data.get('email'),
            country: data.get('country'),
            state: data.get('state'),
            address_1: data.get('address_1'),
            address_2: data.get('address_2') !== "" ? data.get('address_2') :"",
            city: data.get('city'),
            zip: data.get('zip'),
            additional_details: data.get('additional_details') !== "" ? data.get('additional_details') :"",
            card_num: data.get('card_num'),
            expiry_date:`${data.get('expiry_month')} ${data.get('expiry_year')}`,
            cvv: data.get('cvv'),
            card_name: data.get('card_name'),
            i_prefer: data.get('i_prefer'),
            newsletter: data.get('newsletter'),
            policy: data.get('policy'),
            booking_conditions: data.get('booking_conditions'),
            ccType:this.state.ccType,
            token: cookie.get('lt_token'),
            lttrack: cookie.get('lttrack'),
            session_ID:session_ID,
            d:d
        }

        // IF WE ARE MODIFYING
        if(modify!==false){
            body["confirmationNumber"] = modify.confirmationNumber;
            type="update";
        }

        if(modify!==false && this.state.modifyingCard===false){
            body.state = modify.state;
            body.card_num = modify.CC;
            body.expiry_date=modify.expiry;
        }

        const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
        };

        
        let path = modify!==false ? process.env.REACT_APP_UPDATE_RES : process.env.REACT_APP_CREATE_RES ;
        console.log(body);

        // fetch('http://localhost:8000/api/payment', options)
        fetch(`${process.env.REACT_APP_BACK}${path}`, options)
        .then(response => response.json())
        .then(response => {
            if(response.type==='success'){
                this.props.handleSuccessfulBooking(response,type);
                if(!modify){
                    console.log(response);

                    // TRACK WITH GA
                    window.dataLayer.push({"event":"successfulBooking","session_ID":session_ID,"purchase_value":response.total});

                    // TRACK WITH ADS
                    try {
                        let gtagConversion = gtag('event', 'conversion', {
                            'send_to': 'AW-10852432369/DXcYCOrpq5YaEPHz67Yo',
                            'value': response.total,
                            'currency': 'USD'
                        });
                        console.log("gtagConversion",gtagConversion);
                    } catch (error) {
                        console.log("gtag error", error);
                    }

                    try {
                        window.open("/success-track", "", "width=200,height=100");
                    } catch (error) {
                        console.log("success track Error", error);
                    }
                }

            // 432 is a soft success so won't be treated as hard fail
            }else if(response.type==='error' && response.status !== 432){
                button.disabled=false;
                button.innerText="Complete Booking";
                this.failedBooking(response);
            }
            return response;
        })
        .then(response=>{
            this.setState({handle:<Handler key={Math.floor(Math.random() * 6) + 1} event={response} />});
        })
        .catch(err => console.error(err));
    }

    

    handleCountrySelect=(e)=>{
        if(!this.state.countries.hasOwnProperty(e.target.value)){
            this.setState({states:false});
            return;
        }
        this.setState({states:this.state.countries[e.target.value]});
    }

    handleTypeOfCC=(e)=>{
        let type = this.CCtype(String(e.target.value).replaceAll(" ",""));
        if(type!==undefined){
            this.setState({ccType:type});
        }
    }

    expiryDate=(e,type)=>{
        this.setState(prevState=>{
            let newExp = prevState.ccExpiry
            newExp[type]=e.target.value;
            return({ccExpiry:newExp})
        })
    }

    ccExpired=()=>{
        let values = this.state.ccExpiry;
        let currentYear = Number(String(new Date().getFullYear()).slice(-2)) + 2000;
        let cardYear = Number(String(values.year).slice(-2)) + 2000;
        if(cardYear > (currentYear+10)){
            cardYear = Number(String(values.year).slice(-2)) + 1900;
        }
        let cardMonth = Number(String(values.month).substring(0, 2));
        if(cardMonth>12){return {pass:false,msg:"Invalid Month"};}
        let currentMonth = Number(String(new Date().getMonth())) + 1;
        if(parseInt(currentYear) > parseInt(cardYear)){
            return {pass:false,msg:"Card has expired",focus:this.expiry_date.current};
        }
        if(parseInt(currentMonth) > parseInt(cardMonth) && parseInt(currentYear) === parseInt(cardYear)){
            return {pass:false,msg:"Card has expired",focus:this.expiry_date.current};
        }
        return {pass:true};
    }

    ccValidType=(value)=>{
        if(String(value).length < 15){return {pass:false,msg:"Characters missing"};}
        let type = this.state.ccType;
        // console.log(type);
        if(type==="" || type===undefined){
            return {pass:false,msg:"Invalid Card"};
        }
        return {pass:true}
    }

    CCtype=(cc)=>{
        // console.log(cc);
        let amex = new RegExp('^3[47][0-9]{13}$');
        let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
        let cup1 = new RegExp('^62[0-9]{14}[0-9]*$');
        let cup2 = new RegExp('^81[0-9]{14}[0-9]*$');

        let mastercard = new RegExp('^5[1-5][0-9]{14}$');
        let mastercard2 = new RegExp('^2[2-7][0-9]{14}$');

        let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
        let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
        let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');
        
        let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
        let jcb =  new RegExp('^35[0-9]{14}[0-9]*$');

        if (cc==="") {
            return "";
        }
        if (visa.test(cc)) {
            return 'VISA';
        }
        if (amex.test(cc)) {
            return 'AMEX';
        }
        if (mastercard.test(cc) || mastercard2.test(cc)) {
            return 'MASTERCARD';
        }
        if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
            return 'DISCOVER';
        }
        if (diners.test(cc)) {
            return 'DINERS';
        }
        if (jcb.test(cc)) {
            return 'JCB';
        }
        if (cup1.test(cc) || cup2.test(cc)) {
            return 'CHINA_UNION_PAY';
        }
        return undefined;

    }

    formatSpaces=(e,spaces,separator="")=>{
        if(String(e.target.value)===""){return;}
        let value = String(e.target.value).replaceAll(" ",separator);
        var output='';
        if(spaces===4){output=value.match(/.{1,4}/g);}
        if(spaces===2){output=value.match(/.{1,2}/g);}
        e.currentTarget.value = output.join(' ');
    }

    cleanInvalid=(e)=>{
        if(e.target.className!==''){
            e.target.className='';
        }
    }

    cleanExpired=()=>{
        if(this.expiry_date.current.classList.contains("invalid")){
            this.expiry_date.current.classList.remove("invalid");
        }
    }

  render() {
      let modify = this.state.reservation !== false ? PackReservation(this.state.reservation).output : false;
      let currentYear = Number(String(new Date().getFullYear()).slice(-2));
    //   console.log(modify);
      return (
        <>
        {this.state.handle}
        <div className='box'>
        <h3 className='br-10'>Checkout</h3>
        <form onSubmit={(e)=>{this.checkout(e)}} >
            <section>
                <h4 className=''>Contact Info</h4>    
                <div className='flex flex-even'>
                    <input type="text" name="first_name" placeholder='First Name' required={true} defaultValue={modify!==false?modify.name:""} />
                    <input type="text" name="last_name" placeholder='Last Name' required={true} defaultValue={modify!==false?modify.lastname:""} />
                </div>
                <div className='flex flex-even'>
                    <input type="tel" name="phone" placeholder='Phone Number' required={true} defaultValue={modify!==false?modify.phone:""}/>
                    <input type="email" name="email" placeholder='Email' required={true} defaultValue={modify!==false?modify.email:""} />
                </div>
            </section>

            <section>
                <h4 className=''>Billing Address</h4>    
                    <div className=''>
                        <select defaultValue={modify!==false?modify.country:"0"} name="country" onChange={(e)=>{this.handleCountrySelect(e);}} required={true}>
                            <option key="798765423" value="0"  disabled> Country </option>
                            <option key="123456498" value="US" > United States </option>
                            <option key="132165498" value="CA" > Canada </option>
                            <option key="1232135654" value="GB" > United Kingdom </option>
                            <option key="1231654987" disabled>----------------------------</option>
                            {countries.map((c,j)=>{return <option key={j} value={c.code}>{c.name}</option> })}
                        </select>

                        {/* STATES */}
                        {this.state.states !== false ? 
                            <select defaultValue="0" name="state" required={true}>
                                <option key="46513265" value="0" disabled>State / Province</option>
                                {Object.entries(this.state.states).map(([key, value],j)=>{ 
                                    return <option key={j} value={key}>{value}</option> 
                                })}
                            </select>
                        :""}
                    </div>
                    <div className='flex flex-even'>
                        <input type="text" name="address_1" placeholder='Address 1' required={true} defaultValue={modify!==false?modify.address:""}/>
                        <input type="text" name="address_2" placeholder='Address 2' />
                    </div>
                    <div className='flex flex-even'>
                        <input type="text" name="city" placeholder='City' required={true} defaultValue={modify!==false?modify.city:""}/>
                        <input type="text" name="zip" placeholder='Zip / Postal Code' required={true} defaultValue={modify!==false?modify.zip:""}/>
                    </div>
                </section>

                                
                <section>
                <h4 className=''>Additional Details and Preferences</h4>
                    <textarea defaultValue={modify!==false?modify.comments:""} name="additional_details" placeholder="Please note your requests or special needs"></textarea>
                
                </section>

                <section>
                    <h4 className=''>Payment Information</h4>
                    <p className='br-10'>{this.props.paymentPolicy}</p>
                    <div className=''>
                        <div className='flex pad-y-20px card-icons'>
                            <img alt="card" src='/images/Visa.png' className={`${this.state.ccType==='VISA'?"activeCard":""} card`}  />
                            <img alt="card" src='/images/MasterCard.png'  className={`${this.state.ccType==='MASTERCARD'?"activeCard":""} card`} />
                            <img alt="card" src='/images/Amex.png'  className={`${this.state.ccType==='AMEX'?"activeCard":""} card`} />
                            <img alt="card" src='/images/Discover.png'  className={`${this.state.ccType==='DISCOVER'?"activeCard":""} card`} />
                        </div>

                        <div className='flex flex-even'>
                            <input  ref={this.ccType} type="text" disabled={modify!==false?true:false} defaultValue={modify!==false?modify.CC:""} name="card_num" placeholder='Card Number' maxLength="19" required={true} onChange={(e)=>{ this.ccInput = this.handleTypeOfCC(e);this.formatSpaces(e,4);this.cleanInvalid(e) }}/>
                            <input ref={this.card_name} type="text" defaultValue={modify!==false?modify.card_name:""} disabled={modify!==false?true:false} name="card_name" placeholder='Name on Card' required={true}/>
                        </div>
                        <div className='flex flex-even' id="expiry" ref={this.expiry_date}>
                            <select ref={this.expiry_month} disabled={modify!==false?true:false} defaultValue={modify!==false?"**":""} name="expiry_month"  onChange={(e)=>{this.expiryDate(e,"month");this.cleanExpired()}}>
                                <option key="32165" vale={null}>Expiry Month</option>
                                {this.state.months.map((month,i)=>{return <option key={i} value={month}>{month}</option>})}
                            </select>

                            <select ref={this.expiry_year} disabled={modify!==false?true:false} defaultValue={modify!==false?"**":""} name="expiry_year" onChange={(e)=>{this.expiryDate(e,"year");this.cleanExpired()}}>
                                <option key={0} vale={null}>Expiry Year</option>
                                <option key={1} value={currentYear}>{currentYear}</option>
                                <option key={2} value={currentYear+1}>{currentYear+1}</option>
                                <option key={3} value={currentYear+2}>{currentYear+2}</option>
                                <option key={4} value={currentYear+3}>{currentYear+3}</option>
                                <option key={5} value={currentYear+4}>{currentYear+4}</option>
                                <option key={6} value={currentYear+5}>{currentYear+5}</option>
                                <option key={7} value={currentYear+6}>{currentYear+6}</option>
                                <option key={8} value={currentYear+7}>{currentYear+7}</option>
                                <option key={9} value={currentYear+8}>{currentYear+8}</option>
                                <option key={10} value={currentYear+9}>{currentYear+9}</option>
                                <option key={11} value={currentYear+10}>{currentYear+10}</option>
                            </select>
                            <input type="hidden" name="expiryDate" ref={this.ccExpiry} disabled={modify!==false?true:false}/>
                            <input type="hidden" name="d"/>

                            <input ref={this.cvv} type="text" name="cvv" disabled={modify!==false?true:false} defaultValue={modify!==false?"***":""} placeholder='CVV' maxLength="4" required={true} />
                        </div>
                        {modify!==false?
                            <>
                                <p className='br-10'>You can change your Credit Card Details if you wish so. Otherwise, we will retain details from your original reservation.</p>
                                <button type="button" className='btn-action' onClick={(e)=>{this.modifyPaymentMethod(e)}}>Change Payment Method</button>
                            </>
                        :""}
                    </div>
                </section>

                {/* <section>
                    <h4 className=''>Join I Prefer Hotel Rewards</h4>
                    <div className='flex flex-even'>
                        <input type="checkbox" name="i_prefer"/>
                        <p>Yes, sign me up for <em>I Prefer</em> Hotel Rewards so I can receive instant Member benefits like free Wi-Fi, exclusive Member rates, complimentary room upgrades, points for free nights, and other rewards. I agree to the <em>I Prefer</em> Hotel Rewards <a href="https://preferredhotels.com/page/privacy-policy-18" target="_blank">privacy policy</a> and <a href="https://preferredhotels.com/page/i-prefer-program-terms-and-conditions-101" target="_blank">terms and conditions</a>.<br />Once your booking is confirmed, please check your inbox for an email to activate your membership.</p>
                    </div>
                </section> */}

                <section>
                    <h4 className='br-10'>Policies</h4>
                    <div className='flex br-10 gap-30'>
                        <div>
                            <label>Check-in:</label>
                            <p>After 3:00 PM</p>
                        </div>

                        <div>
                            <label>Check-out:</label>
                            <p>Before 11:00 AM</p>
                        </div>
                    </div>
                    <div className='br-20'>
                        <h4 className=''>Guarantee Policy</h4>
                        <p>{this.props.paymentPolicy}</p>
                    </div>
                    <div className='br-20'>
                        <h4>Cancellation Policy</h4>
                        <p>{this.props.cancelPolicy}</p>
                    </div>

                    <div>
                        <h4 className=''>Additional Policy</h4>
                        <p>I understand the max number of guest as noted: Up to 2 guests in a 1 Bedroom Residence, Up to 4 guests in a 2 Bedroom Residence, Up to 6 guests in a 3 Bedroom Residence.</p>
                    </div>

                </section>

                <section>
                    <h4 className=''>Check Below to Complete</h4>
                    <div className='check' id='checkAndText'>
                        <input defaultChecked={true} type="checkbox" name="newsletter" id="checkbox1" />
                        <p>Yes, I would like to receive newsletters and special offers by email.</p>
                    </div>

                    <div className='check'>
                        <input type="checkbox" name="policy" required={true} />
                        <p>* I agree with the <a href='https://lasterrazasresort.com/belize-resort-privacy-policy/'>Privacy Terms</a>.</p>
                    </div>

                    <div className='check'>
                        <input type="checkbox" name="booking_conditions" required={true} />
                        <p>* I agree with the Booking Conditions.</p>
                    </div>
                </section>
                <p ref={this.msg} className='user-msg'>{this.state.msg}</p>
                
                <br/>
                
                <div className='flex flex-y-center'>
                    <button type='submit' className="btn-book">
                        Complete Booking
                    </button>
                    <img alt="cybersource" src='/images/cs-logo.png' width="90"/>
                </div>

        </form>
    </div>
    </>
    )
  }
}

export default Checkout