import React, {Component} from 'react'
import LogIn from '../LogIn/LogIn';
import irates from '../../data/iprefer';
import RateDetailsPop from '../Rate/RateDetailsPop';
import MiniSlideshowGeneric from '../MiniSlideshow/MiniSlideshowGeneric';
import { withRouter } from "react-router-dom";


class SingleExtra extends Component{

  state={
      product: false,
  }


  componentDidMount(){
      
      if(this.state.product === false && this.props.product === false){
        let url = window.location.href;
        let id = url.substring(url.lastIndexOf('/') + 1);
        // console.log(id);

           this.setState({ product :     {
          "id": 4,
          "name": "Group Tour- Hol Chan & Shark Ray ",
          "amount": "USD 70.00",
          "categories": [
            "Caribbean Cruises"
          ],
          "description": "Dive into Hol Chan Marine Reserve where you will snorkel amongst fish, turtles, sting rays, and coral pillars like sea fans, brain corals, and sponges. Then hop on the boat and meet a rush of nurse sharks and sting rays at Shark Ray Alley - great photo opportunities and once-in-a-lifetime memories.",
          "feat": "https://lasterrazasresort.com/wp-content/themes/las_terrazas/dist/images/units/sea_house/hero.webp",
          "gallery": [
            "https://lasterrazasresort.com/wp-content/themes/las_terrazas/dist/images/units/sea_house/hero.webp",
            "https://lasterrazasresort.com/wp-content/themes/las_terrazas/dist/images/units/sea_house/hero.webp",
            "https://lasterrazasresort.com/wp-content/themes/las_terrazas/dist/images/units/sea_house/hero.webp"
          ]
        }});
        return;
        // DEV
        fetch(`${process.env.REACT_APP_BACK}/api/guest-portal-extras/?id=${id}`)
        .then(response => response.json())
        .then(response => {
          this.setState({ product : response[0] });
          
        })
        .catch(err => console.error(err));
      }else{

        this.setState({product : this.props.product });
      }

  }

  handleFormChange = (e) => {
      let form = document.getElementById('extra-form');
      let formData = new FormData(form);
      let formValues = {};
      formData.forEach((value, key) => {
        formValues[key] = value;
      });
      console.log(formValues);
  }

  handleQtyChange = (e) => {
    let qty = document.getElementsByName('qty')[0];
    let qtyValue = parseInt(qty.value);
    qtyValue = !Number.isInteger(qtyValue) ? 0 : qtyValue;
    if(e.target.innerHTML === '−'){
      if(qtyValue > 1){
        qtyValue = qtyValue - 1;
      }
    }else{
      qtyValue = qtyValue + 1;
    }
    qty.value = qtyValue;
    this.handleFormChange(e);
  }
  
  render(){
    // console.log();
    if(this.state.product === false){
      return (<small>Loading</small>);
    }else{
    // window.scrollTo(0,0);
    return (
      <>
        

        <div className='single-extra'>
            <div className=''>
                <div className='slider-container'>
                  <MiniSlideshowGeneric feat={this.state.product.feat} gallery={this.state.product.gallery} />
                </div>
                <br/>

                <div className='flex layout-60-40'>
                  <div>
                    <div className='pad-y-2'>
                <h1 className=''>{this.state.product.name}</h1>
                <p>Exciting attractions and experiences tailored to your interests and travel preferences.</p>
            </div>
                    <p>{this.state.product.description}</p>
                  </div>

                  <div id="extra-form-container">
                    <div id="" class="">
                        <div id="value">{this.state.product.amount}</div>
                    </div>
                    <br/>
                    {/* FORM */}
                    <form id="extra-form" onChange={(e)=>{this.handleFormChange(e)}}>
                      <div>
                        <label>To:</label>
                        <input type="date" name="to"/>
                      </div>
                      <div>
                        <label>From:</label>
                        <input type="date" name="from"/>
                      </div>

                      <div>
                        <label>Quantity</label>
                        <div className='flex flex-walls flex-y-center'>
                          <input type="number" name="qty" defaultValue="1" />
                          <div className='flex'>
                            <span className="qty" onClick={(e)=>{this.handleQtyChange(e)}}>−</span>
                            <span className="qty" onClick={(e)=>{this.handleQtyChange(e)}}>+</span>
                          </div>
                        </div>
                      </div>
                      <button type="button" class="btn-book"><a>Add to cart</a></button>
                    </form>


                  </div>

                </div>
                <br/>
                

            </div>
            <div>
            </div>
        </div>
      </>
    );
    }
  }
}




export default SingleExtra