import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import parse from 'html-react-parser';
import Cookies from 'universal-cookie';
import Handler from './components/Handler/Handler';
import { NotFound } from './pages/NotFound/NotFound';
import { re,dynamicSort,GA_Search } from './utilities/utilities';
import { lt_errors } from './utilities/errors';
import { CookieMonster } from './utilities/utilities';
import { Footer } from './components/Footer/Footer';




// Context
import { AppContextProvider } from "./components/AppContext/AppContext";

// APP ROUTES
import Home from './pages/Home/Home';
import Room from './pages/Room/Room';
import Booking from './pages/Booking/Booking';
import CheckoutTerminal from './pages/CheckoutTerminal/CheckoutTerminal';
import FindBooking from './pages/FindBooking/FindBooking';
import GuestPortal from './pages/Booking/GuestPortal';
import Tracking from './pages/Booking/Tracking/Tracking';
import Order from './pages/Order/Order';
import GatherReviews from './pages/GatherInfo/GatherReviews';
import ThankyouFeedback from './pages/Thanks/ThankyouFeedback';
import GatherInfo from './pages/GatherInfo/GatherInfo';



class App extends Component{
    constructor(props){
        super(props);
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate()+1);
        this.state={
          from:'',
          to:'',
          adult:"",
          child:"",
          RoomStays:{},
          RoomTypes:{},
          maxAdult:6,
          maxChild:5,
          rooms:[],
          context:{},
          selection:{},
          modalOpen:false,
          rateDetail:false,
          handle:false,
          booking:false,
          order:false,
          imember:false,
          timer:false,
          searchMessageDefault:
          <>
            <img src="/images/load.gif" alt="loading gif" width="50px" height="50px"/>
            <p>A Belize adventure awaits!</p>
            <p>Please stand by while we search for rooms</p>
          </>,
          searchMessage:
          <>
            <img src="/images/load.gif" alt="loading gif" width="50px" height="50px"/>
            <p>A Belize adventure awaits!</p>
            <p>Please stand by while we search for rooms</p>
          </>,
          // order:{"charges":{"0":{"charge":"balance 02","value":"88.00"},"1":{"charge":"Kayak rental","value":"100.00"}},"details":{"first_name":"Ashley","last_name":"Leman","phone":"7135013834","email":"ashley.leman@lasterrazasbelize.com","country":"United States of America","address":"","city":"","zipcode":"30024"},"order":{"newMerchantRefNum":"extra_NLmigaEHU2EbpZ4nET0OaJfg_2023_02_06","CCNo":"XXXX XXXX XXXX 9391","charge":"188.00","firstName":"Ashley","lastName":"Leman","address1":"Plaza de la Constitucion 1, Edif Bravo ","locality":"Malaga","administrativeArea":"GA","postalCode":"29001","country":"US","email":"ashley.leman@lasterrazasbelize.com","phoneNumber":"664023587","comments":"My special notes","order_id":"NLmigaEHU2EbpZ4nET0OaJfg"},"type":"success-order","status":210}
        }
      }
    
    

    getURLParams=()=>{
      const urlParams = new URLSearchParams(window.location.search);
        const from = urlParams.get('arrive');
        const to = urlParams.get('depart');
        const adult = urlParams.get('adult');
        const child = urlParams.get('child');
        const promo = urlParams.get('promo');
        // try {}catch(err){}

        // GA CAMPAIGN CUSTOM TRACKING
        

        const lteng = urlParams.get('lteng');
        const ltcmpty = urlParams.get('ltcmpty');
        const ltadgty = urlParams.get('ltadgty') === "null" ? null : urlParams.get('ltadgty');
        const ltadty = urlParams.get('ltadty');
        const trackString = `lteng_${lteng}|ltcmpty_${ltcmpty}|ltadgty_${ltadgty}|ltadty_${ltadty}|`;

        let cookie = new Cookies();
        if(cookie.get('lttrack') === undefined || cookie.get('lttrack') === "undefined" ){ 
            cookie.set(
              'lttrack', 
              trackString,
              { path: '/',maxAge:31556952 }
            ); 
        }
      return {from,to,adult,child,promo};
    }

    formatDate=(date)=>{
        var d = new Date(date);
          // var test = new Date(urlParams.from).getUTCFullYear() + "-" + new Date(urlParams.from).getUTCMonth() + "-" + new Date(urlParams.from).getUTCDate();

        var month = '' + (d.getUTCMonth() + 1);
        var day = '' + d.getUTCDate();
        var  year = d.getUTCFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        console.log(`Format ${[year, month, day].join('-')}`);
        return [year, month, day].join('-');
    }

    handleDateRangeChange = (from,to)=>{
      this.setState(prevState=>{ 
        let newContext = {...prevState.context,from:from,to:to};
        return {context:newContext}
      });
      // this.performSearch(from,to);
    }

    handleDateGuestChange = (dir,guestType)=>{
      this.setState(prevState=>{
        if(guestType==='adult'){
          var adult = prevState.adult + dir;
          adult = adult >= this.state.maxAdult ? this.state.maxAdult : adult ;
          adult = adult <= 1 ? 1 : adult ;

          var newChild = prevState.child;
          if(adult + prevState.child > this.state.maxAdult){
            newChild = prevState.child - dir;
          }
          newChild = newChild <= 0 ? 0 : newChild ;
          return {adult:adult,child:newChild}
        }
        if(guestType==='child'){
          var newChild = prevState.child;
          if(prevState.child + dir + prevState.adult <= this.state.maxAdult){
            newChild = prevState.child + dir;
          }
          newChild = newChild <= 0 ? 0 : newChild ;
          return {child:newChild}
        }
      });
    }

    handleRoomRateSelection=(data,roomCode,code,ratePlan,ratePlanID,bookNow,from="",to="")=>{
      const cookieMonster = new CookieMonster();
      // console.log(from);
      // alert("This also runs")

      // TRACK WITH GA
      window.dataLayer.push({"event":"roomAndRateSelection","roomCode":roomCode,"rateCode":code});
      
      // SET STATE FOR FOLLOWING PAGE
      this.setState(prevState=>{
        let newContext = {...prevState.context,selection:{data,roomCode,code,ratePlan,ratePlanID,bookNow}};
        if(from!==""){
          newContext.from = from;
          newContext.to = to;
        }
        let newState = {context:newContext};
        sessionStorage.setItem('session',JSON.stringify(newContext));
        return(newState);
      });
      
      
      let beSession = {
        data: data.allInfo,
        roomCode,
        code,
        ratePlanID,
        adult:this.state.adult,
        child:this.state.child,
        from:this.formatDate(this.state.context.from),
        to:this.formatDate(this.state.context.to)
      }
      // console.log("COOKIE!",cookie.get('session_ID'));
      var route = '';

      // BE SESSION ID
      let sessionCookie = cookieMonster.get('session_ID');
      if(sessionCookie === undefined || sessionCookie === "undefined" ){
        route = "create-session";
      }else{
        beSession['session_ID']=sessionCookie;
        route = "update-session";
      }

      // LTTRACK 
      let lttrack = cookieMonster.get('lttrack') === null ? "" : `${encodeURIComponent(cookieMonster.get('lttrack'))}`;
      beSession['lttrack']=lttrack;

      // console.log(JSON.stringify(beSession));
      const options = {
        method: 'POST',
        mode: 'cors',
        credentials: "same-origin",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }        ,
        body: JSON.stringify(beSession)
      };

      // console.log(options);

        
      // fetch(`http://localhost:8000/api/${route}`,options)
      console.log("ROUTE",route);
      fetch(`${process.env.REACT_APP_BACK}/api/${route}`,options)
        .then(response => response.json())
        .then(response => {
          console.log(response);
          cookieMonster.setRoomRateSelectionCookies(response);
        })
        .catch(err => console.error(err));

        
      
    }

    handleRateDetails=(data,ratePlan,roomCode)=>{
      
      if(data){
        this.setState({rateDetail:{data,ratePlan,roomCode}});
      }else{
        this.setState({rateDetail:false});
      }
    }

    handleSuccessfulBooking=(data,type="create")=>{
      // SET STATE FOR FOLLOWING PAGE
      this.setState(prevState=>{
        let newState = {booking:{type:type,data:data}};
        return(newState);
      });
    }

    handleSuccessfulOrder=(data,type="create")=>{
      // SET STATE FOR FOLLOWING PAGE: ORDER
      this.setState(prevState=>{
        let newState = {order:{order:data.order,charges:data.charges,details:data.details}};
        return(newState);
      });
    }

    getLowest=(rooms)=>{
      let roomsWithLowest = rooms.map((room,i)=>{
        room.lowest=0.0;
            Object.keys(room.rates).map((rate,j)=>{
                var rateAmount = room.rates[rate].allInfo.Rates.Rate.Base['@attributes'].AmountBeforeTax;
                if(j===0){
                  room.lowest= parseFloat(rateAmount);
                }

                if(parseFloat(rateAmount) < parseFloat(room.lowest)){
                  room.lowest = parseFloat(rateAmount);
                }
            });
            return room;
          });
      roomsWithLowest.sort((a, b) => (a.lowest > b.lowest) ? 1 : -1)
      return roomsWithLowest;
    }

    getNoRooms=(rooms)=>{
      let newRooms = rooms.map((room,i)=>{
        let noRooms = room.TPA_Extensions.BedTypes.BedType.length;
        if(noRooms===undefined){ noRooms=1 }
        room.noRooms = noRooms;
      });
      return rooms;
    }

    sortRooms=(property,order)=>{
      let newSortedRooms = this.state.rooms.sort(dynamicSort(property,order));
      this.setState({rooms:newSortedRooms});
    }

    handle=(event)=>{
      this.setState({handle:<Handler key={Math.floor(Math.random() * 6) + 1} event={event} />});
    }

    handleModifyBooking=(reservation,prevRes)=>{
      // console.log(prevRes);
        this.setState(prevState=>{
        let newContext = {...prevState.context,modify:reservation};
        let newState = {
          context:newContext,
          prevRes:prevRes,
        };
        // sessionStorage.setItem('session',JSON.stringify(newContext));
        return(newState);
        ;});
    }

    handlePreviousResDetails=(prevRes)=>{
      this.handleRoomRateSelection(
        prevRes.data,
        prevRes.roomCode,
        prevRes.code,
        prevRes.ratePlan,
        true,
        prevRes.start,
        prevRes.end
      );
    }

    exitEditMode=()=>{
      this.setState(prevState=>{
        let newContext = {...prevState.context};
        delete newContext.modify;
        let newState = {context:newContext};
        return(newState);
      ;});
    }

    handleCancelBooking=(args)=>{
      const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({confirmationNumber:args.confirmationNumber,email:args.email})
        };

        let path = process.env.REACT_APP_CANCEL_RES;

        // fetch('http://localhost:8000/api/payment', options)
        // console.log(`${process.env.REACT_APP_BACK}${path}`);
        fetch(`${process.env.REACT_APP_BACK}${path}`, options)
        .then(response => response.json())
        .then(response => {
            // console.log(response);
            this.setState({handle:<Handler key={Math.floor(Math.random() * 6) + 1} event={response} />});
        })
        .catch(err => console.error(err));
    }
    

    // AXIOS
    // componentWillMount=()=>{
    //     this.performSearch();
    // }
  
    performSearch = (from=false,to=false,adult=false,child=false,promo=false) =>{
      this.handleRateDetails(false);
      // GET DATES FROM URL
      const urlParams = this.getURLParams();
      // console.log(urlParams);
      if(!from && !to){
        if(urlParams.from === null && urlParams.to === null){
          from = new Date();
          to = new Date();
          to.setDate(to.getDate() + 1);
        }else{
          from = new Date(urlParams.from);
          to = new Date(urlParams.to);
          // console.log(`Here are ${from} - ${to}`);
        }
      }

        
        if(!adult){
          if(urlParams.adult === null){
            adult=2;
          }else{
            adult=parseInt(urlParams.adult);
          }
        }else{
          adult=this.state.adult;
        }
        
        if(!child){
          if(urlParams.child === null){
            child=0;
          }else{
            child=parseInt(urlParams.child);
          }
        }else{
          child=this.state.child;
        }

        // PROMO CODE
        let promoCode = "";
        if(!promo){
          if(urlParams.promo!==""){
            promoCode = `&promo=${urlParams.promo}`;
          }
        }else{
          promoCode = `&promo=${promo}`;
        }
        
        
      this.setState({ 
        rooms:[]
      });

      // TRACKING COOKIES
      let cookie = new Cookies();
      let lttrack= cookie.get('lttrack') === null ? "" : `&lttrack=${encodeURIComponent(cookie.get('lttrack'))}`;
      let dev = process.env.REACT_APP_BACK.includes("manage") ? `&d=ASAMFP` : "";
      
      this.setState({searchMessage:this.state.searchMessageDefault})
      // console.log(`${process.env.REACT_APP_BACK}/api/availability?from=${this.formatDate(from)}&to=${this.formatDate(to)}&adult=${adult}&child=${child}${promoCode}`);
        axios.get(`${process.env.REACT_APP_BACK}/api/availability?from=${this.formatDate(from)}&to=${this.formatDate(to)}&adult=${adult}&child=${child}${promoCode}${lttrack}${dev}`)
        .then(response => {
          console.log(response.data.RoomStays.RoomStay.RatePlans.RatePlan);
          if(response.data.status===undefined && !response.data.hasOwnProperty("error")){
            // if ('RoomStays' in response.data && 'RoomTypes' in response.data.RoomStays.RoomStay){
              this.setState(prevState=>{
                

                // LOWEST
                let roomsWithLowest = this.getLowest(response.data.rooms);
                // NO. OF ROOMS
                roomsWithLowest = this.getNoRooms(roomsWithLowest);
                roomsWithLowest = roomsWithLowest.sort(dynamicSort("noRooms",1));

                // ADD PARAMS TO CONTEXT;
                let newContext = {
                    amenities:response.data.amenities,
                    RatePlans:response.data.RoomStays.RoomStay.RatePlans.RatePlan,
                    handleModal:this.handleModal,
                    handleRoomRateSelection:this.handleRoomRateSelection,
                    from : from,
                    to : to,
                    adult : adult,
                    child : child,
                    promo : promoCode,
                    rooms: roomsWithLowest,
                    year: response.data.year,
                    lttrack: lttrack
                }
                if(prevState.context.hasOwnProperty("modify")){
                  newContext["modify"]=prevState.context.modify
                }

                let newState = {
                  RoomStays:response.data.RoomStays.RoomStay,
                  RoomTypes:response.data.RoomStays.RoomStay.RoomTypes.RoomType,
                  rooms:roomsWithLowest,
                  context:newContext,
                  adult : adult,
                  child : child,
                  promo : promoCode,

                }

                // YEAR
                sessionStorage.setItem('lt_year',JSON.stringify(response.data.year));

                // SESSION
                sessionStorage.setItem('session',JSON.stringify(newContext));
                //GA
                GA_Search(from,to,adult,child);

                                
                return(newState);
                

              });
            // }
          }else{
            if(!response.data.hasOwnProperty("error")){

              this.setState(prevState=>{
                let newContext = {}
                if(prevState.context.hasOwnProperty("modify")){
                  newContext["modify"]=prevState.context.modify
                }
                return({
                RoomStays:{},
                RoomTypes:{},
                rooms:[],
                context:newContext,
                searchMessage:parse(response.data.statusText)
              })
            });

            }else{
              // NO AVAILABILITY
              if(response.data.status===430){
                this.setState({searchMessage:parse(response.data.help)});
              }
            }
            
          }
        })
        
        .catch(error => {
          // SEND EMAIL <------------------------------------------------------------------------------------------------------
          let searchMessage =
          <>
            <p>We apologize for the inconvenience, but we are unable to retrieve any inventory at the moment.<br/><br/> Our Reservation Office remains at your service 7 days/week. <br/>You may reach us at <a href='tel:+17137801233'>(713) 780-1233</a> or <a href='tel:+18004471553'>(800) 447-1553</a> or email <a href='mailto:reservations@lasterrazasbelize.com'>reservations@lasterrazasbelize.com</a></p>
          </>
          this.setState({searchMessage:searchMessage});
          console.log('Error fetching and parsing data from axios', error);
        }); 
    }

  render(){
    const params = {
      from:this.state.context.from,
      to:this.state.context.to,
      adult:this.state.adult,
      child:this.state.child,
      promo:this.getURLParams().promo
    };

    // DON NOT TOUCH LTBE COOKIE - IT'S IRRELEVANT TO THE APP 
      let cookie = new Cookies();
      if(cookie.get('ltbe') === undefined || cookie.get('ltbe') === "undefined" ){ let now = new Date; cookie.set('ltbe', `${now.getTime()}`, { path: '/',maxAge:31556952 }); }
    // DON NOT TOUCH LTBE COOKIE - IT'S IRRELEVANT TO THE APP 
    const superMonster = new CookieMonster();
    // Every minute
    let timer = setInterval(function() {
        superMonster.check();
    }, 60000); 
    return (
      <>
      <AppContextProvider value={this.state.context} >
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={
                <Home 
                  rooms={this.state.rooms} 
                  params={params}
                  searchMessage={this.state.searchMessage}
                  performSearch={this.performSearch}
                  handleDateRangeChange={this.handleDateRangeChange}
                  handleDateGuestChange={this.handleDateGuestChange}
                  handleRoomRateSelection={this.handleRoomRateSelection}
                  handleRateDetails={this.handleRateDetails}
                  rateDetail={this.state.rateDetail}
                  exitEditMode={this.exitEditMode}
                  prevRes={this.state.prevRes !== undefined ? this.state.prevRes : false}
                  handlePreviousResDetails={this.handlePreviousResDetails}
                  sortRooms={this.sortRooms}
                  year={this.state.context.year}
                />
            }/>
            <Route path="/room/:roomCode/:rateCode" element={
              <Room 
                rooms={this.state.rooms}
                rateDetail={this.state.rateDetail}
                handle={this.handle}
                handleSuccessfulBooking={this.handleSuccessfulBooking}
                imember={this.imember}
                exitEditMode={this.exitEditMode}

              />
            }/>
            <Route path="/booking/:success" element={
              <Booking 
                booking={this.state.booking}
                handleModifyBooking={this.handleModifyBooking}
                handleCancelBooking={this.handleCancelBooking}
              />
            }/>
            {/* FEEBACK */}
              <Route path="/feedback" element={
                <GatherReviews 
                  permissionEndpoint="feedback-permission"
                />
              }/>
              <Route path="/thank-you-feedback/" element={<ThankyouFeedback />}/>  

              {/* <Route path="/gather-details" element={
                <GatherInfo 
                  permissionEndpoint="get-details"
                />
              }/>

              <Route path="/guest-portal" element={
                <GuestPortal/>
              }/>

              <Route path="/guest-portal/:page/:product/:id" element={
                <GuestPortal />
              }/> */}

              
              {/* <Route path="/thank-you-feedback/" element={<ThankyouFeedback />}/>   */}

              
            {/* FEEBACK */}

            <Route path="/checkout-terminal" element={
              <CheckoutTerminal handleSuccessfulOrder={this.handleSuccessfulOrder}/>
            }/>
            <Route path="/order/:orderNumber" element={
              <Order order={this.state.order}/>
            }/>               
            {/* NOT FOUND */}
            <Route path="/find-booking" element={
              <FindBooking 
                handleSuccessfulBooking={this.handleSuccessfulBooking}/>
            }/>

            <Route path="/success-track" element={
              <Tracking />
            }/>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
        {this.state.handle}
      </AppContextProvider>
      <Footer />
      </>
  );
  }
}

export default App;
