

const Tracking = () => {
    
    

  return(
    <>
        {setTimeout(()=>{window.close()},10000)} 
    </>
  );
}

export default Tracking;
