import { userDate,formatDollar } from '../../utilities/utilities';
import parse from 'html-react-parser';

export const PackReservation = (reservation) => {
    console.log("PACK",reservation);
    let output={};
    // CONF NUMBER
    output.res_id = reservation.res_id;
    output.is_temp = reservation.hasOwnProperty("is_temp") ? reservation.is_temp : false;
    output.confirmationNumber = reservation.synxis_id;
    output.itineraryNumber = reservation.res_id;
    output.status = reservation.status_string;

    // STAY
    // console.log("Synxis Start",reservation.RoomStays.RoomStay.TimeSpan["@attributes"].Start);
    // console.log("Synxis Start",reservation.RoomStays.RoomStay.TimeSpan["@attributes"].End);
    output.start = userDate(reservation.arrival_date.split(" ")[0]);
    output.end = userDate(reservation.departure_date.split(" ")[0]);
        let date_1 = typeof(output.end) === 'string' ? new Date(output.end) : output.end ;
        let date_2 = typeof(output.start) === 'string' ? new Date(output.start) : output.start ;
        let difference = date_1.getTime() - date_2.getTime();
        output.totalDays = Math.ceil(difference / (1000 * 3600 * 24));

    // GUEST COUNT
    
    let guests = {adult:reservation.adult,child:reservation.child}
    output.guests = guests;

    // ROOM
    output.room = reservation.room_type;
    
    output.roomDetails = "-";

    // MAIN GUEST
    output.name = reservation.first_name;
    output.lastname = reservation.last_name;
    output.email = reservation.email;
    output.address = reservation.address === undefined ? "-" : reservation.address;
    
    output.city = reservation.city === undefined ? "-" : reservation.city;	
    output.country = reservation.country === undefined ? "-" : reservation.country;
    output.state = reservation.state === undefined ? "-" : reservation.state;
    output.zip = reservation.zip === undefined ? "-" : reservation.zip;
    output.phone = reservation.phone === undefined ? "-" : reservation.phone;
    output.comments = reservation.comments === undefined ? "-" : reservation.comments;
        


    // RATE PLAN
    output.ratePlanName = reservation.rate_name;
    output.rateDetails = reservation.rate_description === undefined ? "-" : reservation.rate_description;
    
    output.daily = reservation.daily === undefined ? "0.00" : formatDollar(reservation.daily);
    output.totalCostWithTax = reservation.total === undefined ? "0.00" : formatDollar(reservation.total);

    // POLICIES
    output.policies = reservation.GuaranteePolicy === undefined ? "No policies" : reservation.GuaranteePolicy;
    output.cancelpolicy = reservation.CancelPolicy === undefined ? "No cancel policy" : reservation.CancelPolicy;
    output.deposit = reservation.deposit === undefined ? "0.00" : formatDollar(reservation.deposit);
    output.paid_on = reservation.paid_on === undefined ? "" : userDate(reservation.paid_on.split(" ")[0]);

    output.CC = reservation.card === undefined ? "-" : reservation.card;
    output.expiry = reservation.expiry === undefined ? "" : reservation.expiry;
    output.card_name = reservation.card_name === undefined ? "card name" : reservation.card_name;
    return output;


}
// export const PackReservation = (reservation) => {
//     // console.log("PACK",reservation);
//     let output={};
//     // CONF NUMBER
//     output.confirmationNumber = reservation.UniqueID["@attributes"].ID;
//     output.itineraryNumber = reservation.ResGlobalInfo.HotelReservationIDs.HotelReservationID.find(n=>{return n["@attributes"].ResID_Type === "34"})
//     output.itineraryNumber = output.itineraryNumber["@attributes"].ResID_Value;

//     // STAY
//     // console.log("Synxis Start",reservation.RoomStays.RoomStay.TimeSpan["@attributes"].Start);
//     // console.log("Synxis Start",reservation.RoomStays.RoomStay.TimeSpan["@attributes"].End);
//     output.start = userDate(reservation.RoomStays.RoomStay.TimeSpan["@attributes"].Start);
//     output.end = userDate(reservation.RoomStays.RoomStay.TimeSpan["@attributes"].End);
//         let date_1 = typeof(output.end) === 'string' ? new Date(output.end) : output.end ;
//         let date_2 = typeof(output.start) === 'string' ? new Date(output.start) : output.start ;
//         let difference = date_1.getTime() - date_2.getTime();
//         output.totalDays = Math.ceil(difference / (1000 * 3600 * 24));

//     // GUEST COUNT
//     let guests={};
//     if(reservation.RoomStays.RoomStay.GuestCounts.GuestCount.constructor === Array){
//         reservation.RoomStays.RoomStay.GuestCounts.GuestCount.forEach(g=>{
//             if(g["@attributes"].AgeQualifyingCode==="10"){
//                 guests.adult = g["@attributes"].Count
//             }else{
//                 guests.child = g["@attributes"].Count
//             }
//         });
//     }else{
//        guests = {adult:reservation.RoomStays.RoomStay.GuestCounts.GuestCount["@attributes"].Count,child:0}
//     }

//     output.guests = guests;

//     // ROOM
//     output.room = reservation.RoomStays.RoomStay.RoomTypes.RoomType.RoomDescription["@attributes"].Name;
//     output.roomDetails = reservation.RoomStays.RoomStay.RoomTypes.RoomType.AdditionalDetails.AdditionalDetail;
//     if(output.roomDetails.constructor === Array){
//         output.roomDetails = output.roomDetails.map(rd=>{
//             //console.log(rd.DetailDescription.Text);
//             return rd.DetailDescription.Text
//         });
//         output.roomDetails = output.roomDetails.join('\n');
//     }else{
//         output.roomDetails = output.roomDetails.DetailDescription.Text
//     }
//     output.roomDetails = parse(output.roomDetails);

//     // MAIN GUEST
//     output.name = reservation.ResGuests.ResGuest.Profiles.ProfileInfo.Profile.Customer.PersonName.GivenName;
//     output.lastname = reservation.ResGuests.ResGuest.Profiles.ProfileInfo.Profile.Customer.PersonName.Surname;
//     output.email = reservation.ResGuests.ResGuest.Profiles.ProfileInfo.Profile.Customer.Email;
//     // console.log(reservation.ResGuests.ResGuest.Profiles.ProfileInfo.Profile.Customer.Address.AddressLine);
//     output.address = reservation.ResGuests.ResGuest.Profiles.ProfileInfo.Profile.Customer.Address.AddressLine;
//     if(output.address.constructor === Array ){
//         output.address = reservation.ResGuests.ResGuest.Profiles.ProfileInfo.Profile.Customer.Address.AddressLine.join(" ");
//     }
//     output.city = reservation.ResGuests.ResGuest.Profiles.ProfileInfo.Profile.Customer.Address.CityName;
//     output.country = reservation.ResGuests.ResGuest.Profiles.ProfileInfo.Profile.Customer.Address.CountryName["@attributes"].Code;
//     output.state = reservation.ResGuests.ResGuest.Profiles.ProfileInfo.Profile.Customer.Address.StateProv !== undefined ? reservation.ResGuests.ResGuest.Profiles.ProfileInfo.Profile.Customer.Address.StateProv["@attributes"].StateCode : "";
//     output.zip = reservation.ResGuests.ResGuest.Profiles.ProfileInfo.Profile.Customer.Address.PostalCode;
//     output.phone = reservation.ResGuests.ResGuest.Profiles.ProfileInfo.Profile.Customer.Telephone["@attributes"].PhoneNumber;
//     output.comments = reservation.ResGlobalInfo.Comments!==undefined ? reservation.ResGlobalInfo.Comments.Comment.Text : "No comments";
        


//     // RATE PLAN
//     output.ratePlanName = reservation.RoomStays.RoomStay.RatePlans.RatePlan["@attributes"].RatePlanName;
//     output.rateDetails = reservation.RoomStays.RoomStay.RatePlans.RatePlan.AdditionalDetails.AdditionalDetail;
//     if(output.rateDetails.constructor === Array){
//         output.rateDetails = output.rateDetails.map(rd=>{
//             //console.log(rd.DetailDescription.Text);
//             return rd.DetailDescription.Text
//         });
//         output.rateDetails = output.rateDetails.join('\n');
//     }else{
//         output.rateDetails = output.rateDetails.DetailDescription.Text
//     }
//     output.rateDetails = parse(output.rateDetails);
//     output.dailyRateWithoutTax = formatDollar(reservation.RoomStays.RoomStay.RoomRates.RoomRate.Rates.Rate.Base["@attributes"].AmountBeforeTax);
//     output.totalCostWithTax = formatDollar(reservation.RoomStays.RoomStay.Total["@attributes"].AmountAfterTax);

//     // POLICIES
//     output.policies = reservation.RoomStays.RoomStay.Guarantee.GuaranteeDescription.Text;
//     output.cancelpolicy = reservation.RoomStays.RoomStay.CancelPenalties.CancelPenalty.PenaltyDescription.Text;
//     // output.deadline = reservation.RoomStays.RoomStay.CancelPenalties.CancelPenalty.Deadline["@attributes"].OffsetUnitMultiplier;
//     // console.log("this MF",reservation);
//     try {
//         output.deposit = formatDollar(reservation.RoomStays.RoomStay.Guarantee.AmountPercent["@attributes"].Amount);
        
//     } catch (error) {
//         output.deposit = formatDollar(reservation.RoomStays.RoomStay.Total["@attributes"].AmountAfterTax);
//     }
//     output.today = userDate(new Date().toISOString().slice(0, 10));
//     console.log("Err",reservation.ResGlobalInfo.Guarantee);
//     output.CC = reservation.ResGlobalInfo.Guarantee.GuaranteesAccepted.GuaranteeAccepted.PaymentCard["@attributes"].CardNumber;
//     output.expiry = reservation.ResGlobalInfo.Guarantee.GuaranteesAccepted.GuaranteeAccepted.PaymentCard["@attributes"].ExpireDate;
//     output.expiry = output.expiry.substring(0,2) + " " + output.expiry.substr(-2);
//     output.card_name = reservation.ResGlobalInfo.Guarantee.GuaranteesAccepted.GuaranteeAccepted.PaymentCard.CardHolderName;
//     return {output};


// }
